<template>
  <div id="patient-data-content">
    <div class="patient-data">
      <multiselect
        ref="patient"
        class="patient-name"
        v-model="value.patient_id"
        :options="patients"
        track-by="id"
        label="name"
        :showLabels="false"
        :allowEmpty="false"
        :loading="loading"
        :internal-search="false"
        @search-change="debounceGetPatients"
        placeholder="Selecionar paciente"
        :disabled="disabled"
      >
        <template #singleLabel="props">
          <div class="patient-name-doctor">
            {{ props.option.name }}
          </div>
        </template>
        <template #option="props">
          <div>
            <div class="patient-name">{{ props.option.name }}</div>
            <div class="patient-props">
              <div>{{ parseBirthDay(props.option.birthday) }}</div>
              <div v-if="props.option.birthday && props.option.cpf">|</div>
              <div v-if="props.option.cpf" class="patient-cpf">CPF {{ props.option.cpf }}</div>
            </div>
          </div>
        </template>

        <template #placeholder>
          <span class="multiselect__placeholder">Nome do paciente</span>
        </template>

        <template #noOptions>
          <div>Digite para pesquisar seus pacientes...</div>
        </template>
        <template #noResult>
          <div>Não encontrado paciente cadastrado com este nome...</div>
        </template>
      </multiselect>
    </div>
  </div>
</template>
<script>
import { debounce } from 'lodash'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: "SimplePatientDataSelect",
  components: {},
  props: {
    /**
     * @param {Object} value Mapped as { patient: String } to activate 2-way binding
     */
    value: {
      type: Object,
      default: () => { return { patient_id: null } }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      debounceGetPatients: debounce(this.searchPatient, 300),
      patientSearchQuery: null,
      clinic: getCurrentClinic(),
      patient: null,
      patients: [],
      loading: false,
    }
  },
  methods: {
    parseBirthDay(birthday) {
      if (!birthday) return ''
      return this.moment(birthday).format('DD/MM/YYYY')
    },
    searchPatient(query) {
      this.loading = true
      this.patientSearchQuery = query;
      this.api.searchPatients(this.clinic.id, query)
          .then(res => {
            this.patients = res.data;
          })
          .catch(err => this.$toast.error(err.message))
          .finally(() => {
            this.loading = false
          });
    }
  }
}
</script>

<style lang="scss">
  #patient-data-content {
    width: 100%;
    .patient-data {
      p {
        margin-bottom: 0;
      }

      .patient-title {
        display: flex;
        color:#304388;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 10px;
      }

      .multiselect--disabled {
        opacity: 1;

        .multiselect__select {
          background: #ffffff;
          color: #ffffff;
        }

        .multiselect__tags {
          background: #ffffff;
          color: #ffffff;
        }
      }

      .divider {
        width: 100%;
        height: .5px;
        background-color: var(--neutral-200);
        margin: 10px 0;
      }

      .patient-name {
        margin-bottom: 8px;

        .multiselect__placeholder {
          color:#8696AC;
          font-size: 24px;
          padding: 0px;
        }

        .multiselect__single {
          font-family: Nunito Sans;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: 0em;
          color: #304388;
          padding-left: 0px;

          .export {
            color: #305BF2;
            font-weight: 700;
            margin-right: 2px;
            cursor: pointer;
          }
        }

        &.multiselect--disabled {
          opacity: 1;

          .multiselect__select {
            background: #ffffff;
            color: #ffffff;
          }

          .multiselect__tags {
            background: #ffffff;
            color: #ffffff;
          }
        }
      }

      .multiselect {
        margin-bottom: 8px;

        .multiselect__placeholder {
          color:#8696AC;
          font-size: 24px;
          padding: 0px;
        }

        .multiselect__single {
          font-family: Nunito Sans;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: 0em;
          color: #304388;
          padding-left: 0px;
        }
      }

      input:focus {
        outline: none !important;
        border-color: #305BF2;
      }
    }

    .birthday-wrapper {
      display: flex;
      border-radius: 5px;
      align-items: center;
      flex-direction: row;
      padding: 0px 4px;

      &.active {
        border: 1px solid var(--orange);
        margin-left: 5px;
      }

    }

    .confetti {
      width: 24px;
    }

    .patient-name {
      color: var(--type-active);
      font-size: 16px;
    }

    .patient-profile > span {
      cursor: pointer;
      font-size: 14px;
      font-weight: 700;
      color: var(--blue-500);

      &:hover {
        text-decoration: underline;
      }
    }

    .patient-props {
      display: flex;
      align-items: center;
      gap: 5px;
      color: var(--type-placeholder);
      font-size: 14px;

      .patient-cpf {
        color: var(--blue-500);
        font-weight: 400;
      }
    }

    .inline-flex {
      display: inline-flex;
      align-items: center;
      gap: 20px;
      font-size: 13px;
      margin-bottom: 1rem;
    }

    .flex {
      display: flex;
      align-items: center !important;
      gap: 5px;
    }

    .birthday {
      color: var(--type-active);
      font-weight: 700;
    }

    .icon {
      width: 20px;
      height: 20px;
    }
  }
</style>